import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  App,
  Button,
  Form,
  Input,
  InputNumber,
  QRCode,
  Modal,
  Progress,
  Spin,
  Upload,
  Steps,
} from "antd";
import { io } from "socket.io-client";
import {
  LoadingOutlined,
  CreditCardOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  CopyFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Settings from "../../../Settings";
import CountdownTimer from "../../../Components/CountdownTimer.js";
import {
  ApiPostFile,
  ApiPostFileGateway,
  ApiPostPaymentGateway,
} from "../../../Supports/ApiManager";
import { Player } from "@lottiefiles/react-lottie-player";
import PaymentMethods from "../../../Assets/methods.png";

import success_payment from "../../../Assets/lottiefiles/success_payment.json";
import failed_payment from "../../../Assets/lottiefiles/failed_payment.json";
import wait_payment from "../../../Assets/lottiefiles/wait_payment.json";
import { isMobile } from "react-device-detect";

const PaymentOk = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "50px",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: 600,
          margin: "250px 0",
        }}
      >
        <div style={{ fontSize: "70px", color: "#8c9e12" }}>
          <CheckOutlined />
        </div>
        Toʻlovingiz hisobingizga muvaffaqiyatli oʻtkazildi. <br></br>Siz qayta
        yoʻnaltirilyapsiz
      </div>
    </>
  );
};

const PaymentNO = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "50px",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: 600,
          margin: "250px 0",
        }}
      >
        <div style={{ fontSize: "70px", color: "red" }}>
          <CloseOutlined />
        </div>
        Afsuski, toʻlovingiz amalga oshmadi. <br></br>Iltimos, qayta urinib
        koʻring.
      </div>
    </>
  );
};

const PaymentWait = () => {
  const { t, i18n } = useTranslation();
  const copyContent = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      message.destroy();
      alert("Ko‘chirildi!");
    } catch (err) {
      message.error("Kopyalama Hatası");
    }
  };
  const { message } = App.useApp();
  return (
    <>
      <div style={{ display: "flex", marginBottom: 180 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "100%" : "70%",
            margin: isMobile ? "0px 0px" : "0px 100px",
            marginRight: isMobile ? "0" : "30px",
          }}
        >
          <div
            style={{
              fontSize: "19px",
              textAlign: "left",
              borderRadius: "4px",
              padding: "8px 0px",
              marginTop: 10,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "25px",
                  marginBottom: 20,
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "11px",
                      margin: 0,
                      color: "#969696",
                    }}
                  >
                    Sayt Nomi
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      margin: 0,
                      fontWeight: "600",
                    }}
                  >
                    BETCZN
                  </p>
                </div>
              </div>

              <LoadingOutlined
                style={{ fontSize: 80, marginTop: 100, color: "black" }}
                spin
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "25px",
                  marginBottom: 10,
                  marginTop: 50,
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "19px",
                      margin: 0,
                      paddingBottom: "0",
                      fontFamily: "uzbe-bold",
                    }}
                  >
                    Iltimos kuting..
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: 0,
                      fontFamily: "uzbe-regular",
                    }}
                  >
                    Toʻlovingiz tekshirilmoqda.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p
                style={{
                  fontSize: "14px",
                  width: 250,
                  margin: 0,
                  fontFamily: "uzbe-regular",
                }}
              >
                Agar tranzaktsiyangiz tasdiqlanishi uchun odatdagidan ko'proq
                vaqt ketsa, investitsiyangiz haqida ma'lumot olish uchun jonli
                qo'llab-quvvatlash liniyasidan so'rang.
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: isMobile ? "none" : "flex",
            flexDirection: "column",
            background: "url(https://app.pasopay.com/assets/paso-bg.png)",
            backgroundSize: "cover",
            borderRadius: 27,
            width: isMobile ? "100%" : "100%",
            margin: isMobile ? "0px 0px 0px 0px" : "30px 100px 30px 0px",
            marginLeft: 0,
          }}
        ></div>
      </div>
    </>
  );
};

const Step_1 = ({ getTransaction, getAmount }) => {
  const { message } = App.useApp();
  const [getFormInit] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [getLoader, setLoader] = useState(false);
  const [getPercent, setPercent] = useState(0);

  const [usdToUzs, setUsdToUzs] = useState(null);
  const [usdToUsdt, setUsdToUsdt] = useState(null);
  const [nihaiTutar, setNihaiTutar] = useState(""); // Nihai sonucu saklamak için
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCryptoData = async () => {
    try {
      setLoading(true);
      const [currencyResponse, cryptoResponse] = await Promise.all([
        axios.get("https://v6.exchangerate-api.com/v6/8f9a7ffa5c126dc061186e08/latest/USD"),
        axios.get("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin-cash&vs_currencies=usd"),
      ]);
      const uzsRate = currencyResponse.data?.conversion_rates?.[getTransaction.currency.value];
      const btcToUsd = cryptoResponse.data?.['bitcoin-cash']?.usd;

      if (!uzsRate || !btcToUsd) {
        throw new Error("Döviz veya Bitcoin fiyatı alınamadı.");
      }
      setUsdToUzs(uzsRate);
      setUsdToUsdt(btcToUsd);
      setLoading(false);
    } catch (err) {
      setError(err.message || "Veri alınırken bir hata oluştu.");
      setLoading(false);
    }
  };

  const cleanAmount = (amount, currency) => {
    let cleanAmount = amount;
    if (currency === "UZS" || currency === "USD") {
      cleanAmount = cleanAmount.replace(/[.]/g, "");
      cleanAmount = cleanAmount.replace(",", ".");
    } else {
      cleanAmount = cleanAmount.replace(/[^\d,\.]/g, "");
      cleanAmount = cleanAmount.replace(/[.](?=.*[.])/g, "");
      cleanAmount = cleanAmount.replace(",", ".");
    }
    const numericAmount = parseFloat(cleanAmount) || 0;
    return numericAmount;
  };
  
  const multipliers = [1, 1, 1, 1, 1, 1, 1];
  
  const calculateTotal = () => {
    if (!usdToUzs || !usdToUsdt) {
      return;
    }
  
    const currency = getTransaction.currency.value; // Gelen para birimi
  
    const totalAmountRaw = cleanAmount(getAmount, currency); // Temizlenmiş tutar
  
    let totalAmount = totalAmountRaw;
  
    if (currency === "UZS" || currency === "USD") {
      const amountLength = getAmount.replace(/[^\d]/g, "").length; // Basamak uzunluğu
  
      const multiplier = multipliers[amountLength - 5] || 1; // Çarpanı belirle
  
      totalAmount *= multiplier;
    }
  
    const usdAmount = currency === "UZS" ? totalAmount / usdToUzs : totalAmount;
  
    const usdtTotal = usdAmount / usdToUsdt;
  
    const result = usdtTotal.toFixed(10);
  
    setNihaiTutar(result); // Nihai tutarı kaydet
  };

  useEffect(() => {
    fetchCryptoData();
  }, [getTransaction.currency.value]);

  useEffect(() => {
    if (usdToUzs && usdToUsdt) {
      calculateTotal(); // API verisi geldiğinde hesaplama yap
    }
  }, [usdToUzs, usdToUsdt, getAmount]);


  const [fileList, setFileList] = useState([]);
  const copyContent = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      message.destroy();
      alert("Ko‘chirildi!");
    } catch (err) {
      message.error("Kopyalama Hatası");
    }
  };
  const copyAmount = async (nihaiTutar) => {
    try {
      await navigator.clipboard.writeText(nihaiTutar);
      message.destroy();
      alert("Ko‘chirildi!");
    } catch (err) {
      message.error("Kopyalama Hatası");
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const [getExpireMonth, setExpireMonth] = useState("01");
  const [getExpireYear, setExpireYear] = useState("24");
  const sendRequest = (values) => {
    if (fileList.length === 0) {
      message.error("Dekont Seçiniz");
      return;
    }

    setLoader(true);
    ApiPostFileGateway(
      `/public/payment/transaction/on/${getTransaction.transaction_id}/step/card_custom_info`,
      {
        file: fileList[0],
      },
      (percent) => {
        setPercent(percent);
      }
    )
      .then((response) => {
        if (response.status !== "success") {
          throw new Error(response.message);
        }

        setLoader(false);
      })
      .catch((error) => {
        setPercent(0);
        setFileList([]);
        getFormInit.resetFields();
        Modal.error({
          title: "HATA",
          content:
            error?.response?.data?.message === undefined
              ? error.message
              : error?.response?.data?.message,
        });
        setLoader(false);
      });
  };

  return (
    <>
      {getLoader && (
        <div
          style={{
            width: "100%",
            paddingTop: 50,
            paddingBottom: 50,
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 50, color: "black" }} spin />
            }
          />
        </div>
      )}

      {!getLoader && (
        <>
          <Steps />

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "70%",
                margin: isMobile ? "0px 0px" : "0px 100px",
                marginRight: isMobile ? "0" : "30px",
              }}
            >
              <Form
                form={getFormInit}
                name="ManuelTransfer"
                layout={"vertical"}
                onFinish={sendRequest}
                onFinishFailed={() => {}}
                autoComplete="off"
              >
                <div
                  style={{
                    fontSize: "19px",
                    textAlign: "left",
                    borderRadius: "4px",
                    padding: "8px 0px",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "25px",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "11px",
                            margin: 0,
                            color: "#969696",
                          }}
                        >
                          Sayt Nomi
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          BETCZN
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "11px",
                            margin: 0,
                            color: "#969696",
                          }}
                        >
                          Qolgan vaqt
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          <CountdownTimer />
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "25px",
                        marginBottom: 10,
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "19px",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          To'lov kutilmoqda..
                        </p>
                      </div>
                    </div>
                    <img
                      src="https://uzbepay.com/assets/bitcoincash.svg"
                      height={40}
                      style={{ marginTop: "10px", marginBottom: "20px" }}
                    ></img>

                    <div className="ant-steps ant-steps-vertical ant-steps-medium">
                      <div className="ant-steps-item ant-steps-item-process ant-steps-item-wait">
                        <div className="ant-steps-item-container">
                          <div className="ant-steps-item-tail"></div>
                          <div className="ant-steps-item-icon">
                            <span className="ant-steps-icon">1</span>
                          </div>
                          <div className="ant-steps-item-content">
                            <div
                              className="ant-steps-item-title"
                              style={{ fontFamily: "uzbe-regular" }}
                            >
                              Quyidagi <b>BCH</b> Tarmoq hisobini
                              nusxalash!
                            </div>
                            <div style={{ marginTop: 15 }}>
                              <QRCode
                                type="svg"
                                value={getTransaction?.gateway?.content}
                              />
                            </div>
                            <div
                              className="creditCardHolder"
                              style={{ padding: "0px 0px", background: "none" }}
                            >
                              {/* <header>
                      <span className="logo">
                        <h5 style={{ fontFamily: "uzbe-regular" }}>
                          Test Piaport
                        </h5>
                      </span>
                    </header> */}
                              <div className="cardDetails">
                                <div className="name-number">
                                  <h6 style={{ fontFamily: "uzbe-regular" }}>
                                  BCH{" "}
                                  </h6>
                                  <div style={{ display: "flex" }}>
                                    <h5
                                      className="number"
                                      style={{
                                        fontFamily: "uzbe-regular",
                                        paddingRight: "0px",
                                        fontSize: 13,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          getTransaction?.gateway?.content,
                                      }}
                                    ></h5>
                                    <Button
                                      type="filled"
                                      onClick={() =>
                                        copyContent(
                                          getTransaction?.gateway?.content
                                        )
                                      }
                                      icon={<CopyFilled />}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-steps-item ant-steps-item-process ant-steps-item-wait">
                        <div className="ant-steps-item-container">
                          <div className="ant-steps-item-tail"></div>
                          <div className="ant-steps-item-icon">
                            <span className="ant-steps-icon">2</span>
                          </div>
                          <div className="ant-steps-item-content">
                            <div
                              className="ant-steps-item-title align-center"
                              style={{ fontFamily: "uzbe-regular" }}
                            >
                              Belgilangan miqdorni kripto ilovangizdan
                              ko'chirilgan manzilga yuboring.
                            </div>
                            <br />
                            <div className="ant-steps-item-description">
                              <p
                                style={{
                                  fontSize: "24px",
                                  margin: 0,
                                  fontWeight: "600",
                                }}
                              >
                                {nihaiTutar} BCH
                                <Button
                                  type="filled"
                                  onClick={() => copyAmount(nihaiTutar)}
                                  icon={<CopyOutlined />}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ant-steps-item ant-steps-item-process ant-steps-item-wait">
                        <div className="ant-steps-item-container">
                          <div className="ant-steps-item-tail"></div>
                          <div className="ant-steps-item-icon">
                            <span className="ant-steps-icon">4</span>
                          </div>
                          <div className="ant-steps-item-content">
                            <div
                              className="ant-steps-item-title"
                              style={{ fontFamily: "uzbe-regular" }}
                            >
                              O'tkazishni tugatgandan so'ng, ekran tasvirini
                              oling va uni bizga yuboring.
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Form.Item
                    hasFeedback
                    name="file"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: "Ushbu qator to'ldirilishi shart!",
                      },
                    ]}
                  >
                    <Upload style={{ width: "100%", marginTop: 0 }} {...props}>
                      <Button icon={<UploadOutlined />}>
                        Kvitansiyani yuklash
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
              </Form>

              <div
                style={{
                  fontSize: "19px",
                  textAlign: "center",
                  borderRadius: "4px",
                  fontFamily: "uzbe-regular",
                  padding: isMobile ? "0px 0px" : "15px 0px",
                  marginTop: 10,
                  marginBottom: 15,
                  width: isMobile ? "100%" : "40%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                }}
              >
                <Button
                  style={{
                    width: isMobile ? "100%" : "70%",
                    background: "#000",
                    fontFamily: "uzbe-regular",
                    border: "2px solid #000",
                    padding: "25px",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    marginRight: 7,
                  }}
                  type={"primary"}
                  shape={"round"}
                  loading={getLoader}
                  onClick={() => getFormInit.submit()}
                >
                  Transferni tasdiqlang
                </Button>
              </div>
            </div>

            <div
              style={{
                display: isMobile ? "none" : "flex",
                flexDirection: "column",
                backgroundSize: "cover",
                borderRadius: 27,
                width: isMobile ? "100%" : "100%",
                margin: isMobile ? "0px 0px 0px 0px" : "30px 100px 30px 0px",
                marginLeft: 0,
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

const Method = (props) => {
  const { message, notification, modal } = App.useApp();

  const { t, i18n } = useTranslation();
  const [getSocketConnected, setSocketConnected] = useState(
    "socket_connect_wait"
  );
  const [getAmount, setAmount] = useState("0.00");
  const [getInRoom, setInRoom] = useState(false);
  const [getLoadingConfirm, setLoadingConfirm] = useState(false);
  const [getTransaction, setTransaction] = useState(props);
  const [getSocketInit, setSocketInit] = useState(false);

  useEffect(() => {
    window.addEventListener("focus", getUpdate);
    return () => {
      window.removeEventListener("focus", getUpdate);
    };
  }, []);

  const getUpdate = () => {
    ApiPostPaymentGateway(
      `/public/payment/transaction/${getTransaction.transaction_id}/view`
    )
      .then((response) => {
        setTransaction(response.result);

        setInRoom(response.result.status.value !== "payment_waiting_room");

        if (getSocketInit) {
          getSocketInit.connect();
        }

        if (response.result.status.value === "payment_success") {
          setInterval(() => {
            window.location.href = response.result.success_callback;
          }, 2000);
        }

        if (response.result.status.value === "payment_failed") {
          setInterval(() => {
            window.location.href = response.result.error_callback;
          }, 2000);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const confirmUpdate = () => {
    setLoadingConfirm(true);

    ApiPostPaymentGateway(
      `/public/payment/transaction/on/${getTransaction.transaction_id}/confirm`
    )
      .then((response) => {
        setLoadingConfirm(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoadingConfirm(false);
      });
  };

  useEffect(() => {
    setInRoom(getTransaction.status.value !== "payment_waiting_room");

    const formatter = new Intl.NumberFormat("tr-TR", {});

    setAmount(formatter.format(getTransaction.amount));

    const SocketConnectServer = io(Settings.socket_server, {
      query: {
        merchant_id: getTransaction.merchant_id,
        transaction_id: getTransaction.transaction_id,
        sender: "client",
      },
    });

    setSocketInit(SocketConnectServer);

    SocketConnectServer.on("connect", () => {
      SocketConnectServer.emit("public:payment:join:send", {
        random: Math.random(),
      });

      console.info("Soket bağlantısı sağlandı");

      setSocketConnected("socket_connect_ok");

      setTimeout(() => setSocketConnected("socket_connected"), 500);
    });

    SocketConnectServer.on("disconnect", (disconnect) => {
      getUpdate();
      setInterval(() => {
        SocketConnectServer.connect();
      }, 1000);
      setSocketConnected("disconnect");
      console.error("Soket bağlantısı koptu");
    });

    SocketConnectServer.on("system:payment:list:receiver", (data) => {
      getUpdate();
      console.info(
        "Soket bağlantısı güncellendi",
        "system:payment:list:receiver"
      );
    });

    SocketConnectServer.on("connect_error", () => {
      setInterval(() => {
        SocketConnectServer.connect();
      }, 1000);
    });

    return () => {
      SocketConnectServer.offAny();
      SocketConnectServer.removeAllListeners();
      SocketConnectServer.disconnect();
      setSocketConnected("socket_connect_no");

      console.info("Soket bağlantısı güncellendi", "Bağlantı kapatıldı");
    };
  }, []);

  const copyContent = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      message.destroy();
      message.success(`(${content}) Kopyalandı`);
    } catch (err) {
      message.error("Kopyalama Hatası");
    }
  };

  if (getSocketConnected === "socket_connected") {
    if (getInRoom) {
      return (
        <>
          <div
            style={{
              fontSize: "22px",
              textAlign: "center",
              borderRadius: "4px",
              padding: "8px 0px",
              width: isMobile ? "100%" : "100%",
              paddingTop: "0",
              fontWeight: 700,
              textTransform: "uppercase",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              background: "#fff",
              color: "#553176",
            }}
          ></div>

          {["payment_success"].includes(getTransaction?.status?.value) && (
            <PaymentOk getTransaction={getTransaction} getAmount={getAmount} />
          )}

          {["payment_failed"].includes(getTransaction?.status?.value) && (
            <PaymentNO getTransaction={getTransaction} getAmount={getAmount} />
          )}

          {["payment_confirmed", "payment_on_process", "info_ok"].includes(
            getTransaction?.status?.value
          ) && (
            <PaymentWait
              getTransaction={getTransaction}
              getAmount={getAmount}
            />
          )}

          {["take_sender_account"].includes(getTransaction?.status?.value) && (
            <>
              <Step_1 getTransaction={getTransaction} getAmount={getAmount} />

              {/*
                            <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "0px 0px"}}>
                                    {t("sendAmount")} <span style={{fontWeight:700}}>{getAmount}</span>
                                </div>
                                <div style={{fontSize: "19px", textAlign: "center", borderRadius: "4px", padding: "8px 0px", marginTop:25, marginBottom:10, width:"100%", alignItems:"center", justifyContent:   "center", display:"flex"}}>
                                    <Button style={{width:"70%"}} type={"primary"} shape={"round"} loading={getLoadingConfirm} onClick={confirmUpdate}>{t("confirmPay")}</Button>
                                </div>
                        */}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 450,
                height: "auto",
                padding: 20,
                background: "#FFFFFF",
                minHeight: 450,
                borderRadius: "0 0 30px 30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 50, color: "black" }}
                    spin
                  />
                }
              />
              <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: 17, fontWeight: 400 }}>
                  {t("in_wait")}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#FFFFFF",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "auto",
              padding: 20,
              background: "#FFFFFF",
              minHeight: 450,
              borderRadius: "0 0 30px 30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 17, fontWeight: 400 }}>
                Toʻlov jarayoni boshlanmoqda.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Method;
